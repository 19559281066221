import React, { useState, useContext, useEffect } from "react";
import { graphql, Link } from "gatsby";
import styled from "styled-components";
import { PrismicRichText } from "@prismicio/react";

// Hooks
import { useMediaQuery } from "../hooks/useMediaQuery";

// Components
import { AspectRatioImageContainer } from "../containers/aspect-ratio-image-container";

// Utils
import kebabCase from "lodash.kebabcase";

const ArticleContainer = styled.article`
  border: 1px solid ${(props) => props.color};

  & .content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    padding: 30px;

    background-color: #fff;
    transition: 300ms background-color ease, 300ms color ease;

    @media (max-width: 768px) {
      padding: 15px;
    }
  }

  &:hover {
    & .content {
      background-color: ${(props) => props.color};
      color: #fff;
    }
  }

  text-align: center;

  & .article-link {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;

    flex: 1;
  }

  & .category {
    font-size: 14px;

    margin: 0;

    @media (max-width: 768px) {
      font-size: 12px;
    }
  }

  & h1 {
    margin: 10px 0;
  }

  & ol {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;

    & li {
      & a {
        font-size: 14px;
        letter-spacing: 1px;

        text-transform: uppercase;

        @media (max-width: 768px) {
          font-size: 12px;
        }
      }

      &::after {
        content: "·";
        padding: 0 5px;
      }

      &:last-of-type {
        &::after {
          content: "";
        }
      }
    }
  }

  @media (max-width: 768px) {
    margin: 0 0 20px 0;
  }
`;

export const Article = ({ item, index }) => {
  // Media Query
  let isDesktop = useMediaQuery("(min-width: 769px)");

  const tags = item.tags.map((tag, innerIndex) => (
    <li key={`single_item_tag_${index}_${innerIndex}`} className="tag">
      <Link to={`/${kebabCase(tag)}/`}>{tag}</Link>
    </li>
  ));

  return (
    <ArticleContainer
      color={
        item.data.content_type.document !== null
          ? item.data.content_type.document.data.color
          : `#000`
      }
    >
      <AspectRatioImageContainer image={null} padding={isDesktop ? 133 : 105}>
        <p className="category tag">
          {item.data.content_type.document !== null
            ? item.data.content_type.document.data.content_type
            : ``}
        </p>

        <Link to={item.url} className="article-link">
          <PrismicRichText field={item.data.title.richText} />
        </Link>

        <ol className="item-tags">{tags}</ol>
      </AspectRatioImageContainer>
    </ArticleContainer>
  );
};
