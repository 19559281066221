import React from "react";
import styled from "styled-components";

const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 80px;

  @media (max-width: 1000px) {
    grid-gap: 40px;
    grid-template-columns: 1fr 1fr;
  }

  @media (max-width: 768px) {
    display: block;
    grid-template-columns: unset;
    grid-gap: unset;
  }
`;

export const ContentContainer = ({ children }) => {
  return <Container>{children}</Container>;
};
